import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { reportTemplatePlayer } from 'cccisd-laravel-appdefs';
import { Report, Page } from 'cccisd-laravel-report';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';

import widgets from '../widgets/widgets.js';
import style from './style.css';
import responsesQuery from './responses.graphql';

import ReportHeader from '../../common/LIFT_Report_Header/index.js';
import ReportFooter from '../../common/LIFT_Report_Footer/index.js';

const Fortress = window.cccisd && window.cccisd.fortress;
const pawnId = Fortress.user.acting.id;

const ANSWER_MAX_CHAR_LENGTH = 200;

const Component = props => {
    const [reportAnswers, setReportAnswers] = useState({
        s: '',
        m: '',
        a: '',
        r: '',
        t: '',
        smart: '',
    });
    const [loading, setLoading] = useState(true);

    const {
        widgets: {
            reportTitle: {
                props: {
                    settings: { moduleId, moduleTitle },
                },
            },
        },
    } = props;

    useEffect(() => {
        getData();
    }, []);

    async function getData() {
        const response = await client.query({
            query: responsesQuery,
            fetchPolicy: 'network-only',
            variables: {
                pawnId,
            },
        });

        const data = response.data.flows.assignmentProgress.devTags;

        setReportAnswers({
            s: data.SpecificGoal ? data.SpecificGoal.slice(0, ANSWER_MAX_CHAR_LENGTH) : '',
            m: data.MeasurableGoal ? data.MeasurableGoal.slice(0, ANSWER_MAX_CHAR_LENGTH) : '',
            a: data.AchievableGoal ? data.AchievableGoal.slice(0, ANSWER_MAX_CHAR_LENGTH) : '',
            r: data.RealisticGoal ? data.RealisticGoal.slice(0, ANSWER_MAX_CHAR_LENGTH) : '',
            t: data.TimelyGoal ? data.TimelyGoal.slice(0, ANSWER_MAX_CHAR_LENGTH) : '',
            smart: data.SMARTGoal ? data.SMARTGoal.slice(0, ANSWER_MAX_CHAR_LENGTH) : '',
        });

        setLoading(false);
    }

    if (loading) {
        return <Loader loading type="inline" />;
    }

    return (
        <div style={{ width: '840px', margin: '0 auto', maxWidth: '100%' }}>
            <Report height="600px" downloadFilename="LIFT_Module_2_Report">
                <Page>
                    <div className={style.page}>
                        <ReportHeader chapterId={moduleId} chapterTitle={moduleTitle} />
                        {!props.isPreview && (
                            <div
                                style={{
                                    display: 'grid',
                                    position: 'relative',
                                    backgroundColor: 'black',
                                    gap: '2px',
                                    padding: '2px',
                                    gridTemplateColumns: '.125fr 1fr 1fr 1fr 1.5fr',
                                }}
                            >
                                <div className={style.grid_block} style={{ backgroundColor: 'rgb(216,210,188)' }} />
                                <div className={style.grid_block}>
                                    <div className={style.title}>Component</div>
                                </div>
                                <div className={style.grid_block}>
                                    <div className={style.title}>Definition</div>
                                </div>
                                <div className={style.grid_block}>
                                    <div className={style.title}>Example</div>
                                </div>
                                <div className={style.grid_block}>
                                    <div className={style.title}>My Plan</div>
                                </div>
                                <div className={style.grid_block} style={{ backgroundColor: 'rgb(93,180,164)' }} />
                                <div className={style.grid_block}>
                                    <div className={style.letter_container}>
                                        <div className={style.letter}>S</div>
                                        <div className={style.word}>SPECIFIC</div>
                                    </div>
                                </div>
                                <div className={style.grid_block}>
                                    <div className={style.example}>Specify whom, what, why and how.</div>
                                </div>
                                <div className={style.grid_block}>
                                    <div className={style.example}>
                                        I will try to reduce my stress by walking my dog more often.
                                    </div>
                                </div>
                                <div className={style.grid_block}>
                                    <div className={style.example}>{reportAnswers.s}</div>
                                </div>
                                <div className={style.grid_block} style={{ backgroundColor: 'rgb(216,210,188)' }} />
                                <div className={style.grid_block}>
                                    <div className={style.letter_container}>
                                        <div className={style.letter}>M</div>
                                        <div className={style.word}>MEASURABLE</div>
                                    </div>
                                </div>
                                <div className={style.grid_block}>
                                    <div className={style.example}>How will you measure your progress?</div>
                                </div>
                                <div className={style.grid_block}>
                                    <div className={style.example}>
                                        I will take my dog for a 20-minute walk three days per week when I get home from
                                        school. I will keep a log to make sure I do it.
                                    </div>
                                </div>
                                <div className={style.grid_block}>
                                    <div className={style.example}>{reportAnswers.m}</div>
                                </div>
                                <div className={style.grid_block} style={{ backgroundColor: 'rgb(64,128,111)' }} />
                                <div className={style.grid_block}>
                                    <div className={style.letter_container}>
                                        <div className={style.letter}>A</div>
                                        <div className={style.word}>ATTAINABLE</div>
                                    </div>
                                </div>
                                <div className={style.grid_block}>
                                    <div className={style.example}>Is it doable and realistic?</div>
                                </div>
                                <div className={style.grid_block}>
                                    <div className={style.example}>Yes, I have time to walk my dog after school.</div>
                                </div>
                                <div className={style.grid_block}>
                                    <div className={style.example}>{reportAnswers.a}</div>
                                </div>
                                <div className={style.grid_block} style={{ backgroundColor: 'rgb(240,100,99)' }} />
                                <div className={style.grid_block}>
                                    <div className={style.letter_container}>
                                        <div className={style.letter}>R</div>
                                        <div className={style.word}>RELEVANT</div>
                                    </div>
                                </div>
                                <div className={style.grid_block}>
                                    <div className={style.example}>Does your goal align with your current needs?</div>
                                </div>
                                <div className={style.grid_block}>
                                    <div className={style.example}>
                                        Yes, walking my dog is a fun activity and good exercise, and it will help reduce
                                        my stress. Plus, it will make my dog happy.
                                    </div>
                                </div>
                                <div className={style.grid_block}>
                                    <div className={style.example}>{reportAnswers.r}</div>
                                </div>
                                <div className={style.grid_block} style={{ backgroundColor: 'rgb(178,173,153)' }} />
                                <div className={style.grid_block}>
                                    <div className={style.letter_container}>
                                        <div className={style.letter}>T</div>
                                        <div className={style.word}>TIME-BOUND</div>
                                    </div>
                                </div>
                                <div className={style.grid_block}>
                                    <div className={style.example}>What is the time frame for achieving your goal?</div>
                                </div>
                                <div className={style.grid_block}>
                                    <div className={style.example}>
                                        {` If I stick to my plan, I will walk my dog at least three times per week while I'm
                                    completing the LIFT program.`}
                                    </div>
                                </div>
                                <div className={style.grid_block}>
                                    <div className={style.example}>{reportAnswers.t}</div>
                                </div>
                                <div className={style.grid_block} style={{ backgroundColor: 'rgb(178,173,153)' }} />
                                <div className={style.grid_block} style={{ minHeight: '80px' }}>
                                    <div
                                        className={style.title}
                                        style={{ fontFamily: 'Bebas Neue, sans-serif', fontSize: '25px' }}
                                    >
                                        NAME YOUR GOAL:
                                    </div>
                                </div>
                                <div className={style.grid_block} style={{ gridColumn: '3 / 6' }}>
                                    <div className={style.example}>{reportAnswers.smart}</div>
                                </div>
                            </div>
                        )}
                        <ReportFooter />
                    </div>
                </Page>
            </Report>
        </div>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    widgets: PropTypes.object,
    data: PropTypes.object,
    widgetsLoaded: PropTypes.array,
};

// reportTemplatePlayer wraps the Report Player with the Filter Bar. This is also where the FilterBar lives and you define your filters. The filters are passed to the Player and Widgets.
export default reportTemplatePlayer({
    widgets,
})(Component);
